/* eslint-disable no-unused-vars */
import moment from 'moment'
import Link from 'next/link'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { useIdleTimer } from 'react-idle-timer'
import NotificationsIcon from '@material-ui/icons/Notifications'

import { SET_SERVICE_WORKER_REGISTRATION } from '../../redux/actions'

import {
  Row,
  Icon,
  Home,
  Column,
  Content,
  Dropdown,
  TextIcon,
  UserInfo,
  RightMenu,
  MiddleMenu,
  HomeWrapper,
  StyledBadge,
  DropdownList,
  UserInfoText,
  LintasWrapper,
  HeaderWrapper,
  WrapperDropdown,
  LayoutBackground,
  IconButtonWrapper,
  ProfileButtonWrapper
} from './header.styled'
import { DashboardAPI, NotificationAPI } from '../../api'

import {
  Alerts,
  HomeIcon,
  LintasIcon,
  ListNotification,
  ModalConfirmation,
  Logout as LogoutIcon
} from '../../components'
import authService from '../../utils/authservice'

const useStyles = makeStyles(() => ({
  notificationIcon: {
    width: '2.56vh',
    height: '3.07vh',
    cursor: 'pointer',
    margin: '1.02vh 0px 0px 0px'
  },
  containerDialog: {
    flex: 1,
    textAlign: 'center'
  },
  topHalf: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F4F6F9'
  },
  bottomHalf: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  count: {
    top: '0.3vw',
    right: '5.2vw'
  }
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  arrow: {
    '&:before': {
      border: 'none'
    },
    color: 'transparent'
  }
}))(Tooltip)

const Header = (props) => {
  const user = localStorage.getItem('user_info')
    ? JSON.parse(localStorage.getItem('user_info'))
    : null

  const router = useRouter()
  const path = router.asPath
  // const param = router.query
  const classes = useStyles()
  const { publicRuntimeConfig } = getConfig()
  const RUN_OUTSCOPE_FUNCTION =
    publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'
  const [access, setAccess] = useState([])
  const [modal, setModal] = useState(false)
  const [notif, setNotif] = useState(false)
  const [homeUrl, setHomeUrl] = useState('')
  // const [logout, setLogout] = useState(false)
  const [dataNotif, setDataNotif] = useState()
  const [countNotif, setCountNotif] = useState()
  const [usersTimeout, setUserTimeout] = useState(0)
  const [logoutRedirectUrl, setLogoutRedirectUrl] = useState('')
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)
  const { roleMapping } = useSelector((state) => state.usersLoginReducer)
  const { isRegistered } = useSelector((state) => state.serviceWorkerReducer)
  const dataUserProfile = useSelector((state) => state.usersProfileReducer)
  const dispatch = useDispatch()

  const _getUrls = async () => {
    try {
      const url = localStorage.getItem('logout_url')
      if (url == null || url == '') {
        const filterLogout =
          '[{"field":"type","type":"text","value":"Lintas_URL"},{"field":"is_active","type":"bool","value":"true"}]'
        const { data: logoutUrl } = await DashboardAPI.GetHeaderButtonUrls(
          0,
          100,
          filterLogout
        )
        setLogoutRedirectUrl(logoutUrl[0].Value)
        localStorage.setItem('logout_url', logoutUrl[0].Value)
      } else {
        setLogoutRedirectUrl(url)
      }

      const filterHome =
        '[{"field":"type","type":"text","value":"Lintas_Home"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data: homeUrl } = await DashboardAPI.GetHeaderButtonUrls(
        0,
        100,
        filterHome
      )
      setHomeUrl(homeUrl[0].Value)

      const filterTimeout =
        '[{"field":"type","type":"text","value":"Session_Timeout_Web"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data: timeout } = await DashboardAPI.GetHeaderButtonUrls(
        0,
        100,
        filterTimeout
      )
      setUserTimeout(parseInt(timeout[0].Value))
    } catch (error) {
      console.log(error)
    }
  }

  const _handleCloseModalNotif = () => {
    setNotif(false)
  }

  const _handleHomeButtonClick = () => {
    window.location = homeUrl
  }

  const _handleLogoutConfirmation = async () => {
    console.log('logging out')
    const serviceWorker = await navigator.serviceWorker.getRegistration()
    if (serviceWorker) {
      await serviceWorker.unregister()
    }
    if (
      logoutRedirectUrl.toString().includes('dev') ||
      logoutRedirectUrl.toString().includes('test')
    ) {
      await authService.signOut()
    } else {
      setShowLogoutDialog(!showLogoutDialog)
      if (logoutRedirectUrl == null || logoutRedirectUrl == '') {
        window.location = publicRuntimeConfig.LINTAS_PATH
      } else {
        window.location = logoutRedirectUrl
      }
    }
  }

  const _setAccess = () => {
    if (roleMapping && roleMapping.length) {
      const menu = roleMapping
        .filter((rl) => rl.parentId == 0)
        .map((acc) => {
          return Object.assign({
            ...acc,
            subMenu: roleMapping.filter((acs) => acs.parentId == acc.id)
          })
        })
      setAccess(menu)
    }
  }

  const _getNotif = async () => {
    try {
      const { data } = await DashboardAPI.GetNotification()
      let count = 0
      let dataFilter = []
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i]['Is_Action_Taken'] === false) {
            if (data[i]['Is_Read'] === false) {
              count++
            }
            dataFilter.push(data[i])
          }
        }
      }
      setDataNotif(dataFilter)
      setCountNotif(count)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      _getNotif()
    }, 10000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    _getUrls()
    _setAccess()
    _getNotif()
    localStorage.url !== undefined
      ? window.localStorage.removeItem('url')
      : null
    // setModal(props.expired || false)
  }, [])

  useEffect(() => {
    if (!user && logoutRedirectUrl) {
      localStorage.clear()
      window.location = logoutRedirectUrl
    }
  }, [logoutRedirectUrl])

  const urlBase64ToUint8Array = (base64String) => {
    var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    var base64 = (base64String + padding)
      .replace(/\-/g, '+') //eslint-disable-line
      .replace(/_/g, '/')

    var rawData = window.atob(base64)
    var outputArray = new Uint8Array(rawData.length)

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  const _setRegistered = () => {
    dispatch({
      type: SET_SERVICE_WORKER_REGISTRATION,
      payload: {
        isRegistered: true
      }
    })
  }

  const subscribePushNotif = () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(async (registration) => {
        // console.log('SW registration successful with scope: ', registration.scope) //COMMENT-OUT

        const UserID = localStorage.getItem('user_info')
          ? JSON.parse(localStorage.getItem('user_info')).User.ID
          : null
        const { data: vapidKey } = await NotificationAPI.GenerateVapid(UserID)
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapidKey)
        }
        const existingSubscription =
          await registration.pushManager.getSubscription()
        // console.log('Existing subscription', existingSubscription) //COMMENT-OUT
        if (existingSubscription) {
          await existingSubscription.unsubscribe()
        }

        registration.pushManager
          .subscribe(subscribeOptions)
          .then((pushSubscription) => {
            const subscriptionObjectStringified =
              JSON.stringify(pushSubscription)
            // console.log('Received PushSubscription: ', subscriptionObjectStringified) //COMMENT-OUT
            NotificationAPI.UpdateSubscription(
              UserID,
              subscriptionObjectStringified
            )
              .then((res) => {
                console.log('Update Subscription Result: ', res) //COMMENT-OUT
                _setRegistered()
              })
              .catch((err) => {
                console.log('Error Updating Subscription', err)
              })
          })
          .catch((err) => {
            console.log('Push subscription failed: ', err)
          })
      })
      .catch((err) => {
        console.log('Service Worker registration failed: ', err)
      })
  }

  useEffect(() => {
    if (RUN_OUTSCOPE_FUNCTION && 'PushManager' in window) {
      if ('serviceWorker' in navigator) {
        new Promise(function (resolve, reject) {
          const permissionResult = Notification.requestPermission((result) => {
            resolve(result)
          })

          if (permissionResult) {
            permissionResult.then(resolve, reject)
          }
        })
          .then((permissionResult) => {
            if (permissionResult === 'granted') {
              console.log('permission for notification granted')
              if (!isRegistered) {
                subscribePushNotif()
              } else {
                // console.log('Service worker for push notification is already registered') //COMMENT-OUT
              }
            } else {
              console.log('no notification permission granted!')
            }
          })
          .catch((err) => {
            console.log('Notification Permission request failed: ', err)
          })
      } else {
        console.log('no service worker')
      }
    } else {
      console.log('no push manager')
    }
  }, [])

  // const handleOnIdle = async () => {
  //   //logout / force redirect user to login page lintas
  //   const userTimeout = localStorage.getItem('userTimeout')
  //     ? localStorage.getItem('userTimeout')
  //     : null

  //   if (
  //     path !== '/' &&
  //     usersTimeout != 0 &&
  //     userTimeout != null &&
  //     !path.includes('dashboard') &&
  //     moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(userTimeout), 'minute') >=
  //       usersTimeout &&
  //       !logout
  //   ) {
  //     const serviceWorker = await navigator.serviceWorker.getRegistration()
  //     if (serviceWorker) {
  //       await serviceWorker.unregister()
  //     }
  //     setModal(true)
  //     localStorage.clear()
  //     param.sr_no ? localStorage.setItem('url', window.location.href) : null
  //   }
  // }

  const handleOnAction = () => {
    if (
      path === '/' ||
      usersTimeout == 0 ||
      path.includes('dashboard') ||
      localStorage.getItem('user_info')
    ) {
      localStorage.setItem(
        'userTimeout',
        moment().format('YYYY-MM-DD HH:mm:ss')
      )
      return
    }
    return
  }

  useIdleTimer({
    debounce: 500,
    // onIdle: handleOnIdle,
    onAction: handleOnAction,
    timeout: 1000 * 60 * parseInt(usersTimeout)
  })

  const _onClose = () => {
    setModal(false)
    if (logoutRedirectUrl == null || logoutRedirectUrl == '') {
      window.location = publicRuntimeConfig.LINTAS_PATH
    } else {
      window.location = logoutRedirectUrl
    }
  }

  const _alert = () => {
    return (
      <Alerts
        open={modal}
        expired={true}
        close={() => _onClose()}
        msg={'Please re-login!'}
      />
    )
  }

  return (
    <LayoutBackground>
      <HeaderWrapper>
        <Row>
          <Column style={{ justifyContent: 'flex-start' }}>
            <HomeWrapper>
              <Home onClick={_handleHomeButtonClick} id='header-home-btn'>
                <Icon>
                  <HomeIcon width='2.45vh' height='2.45vh' />
                </Icon>
                <TextIcon>HOME</TextIcon>
              </Home>
            </HomeWrapper>
            <LintasWrapper>
              <LintasIcon width='15.3vh' height='6.15vh' />
            </LintasWrapper>
          </Column>
          <Column>
            {access.map((menu) => {
              if (menu.activeName === 'monitoring') {
                return (
                  RUN_OUTSCOPE_FUNCTION && (
                    <Link href={`${menu.path}`} key={menu.id}>
                      <MiddleMenu
                        active={path.includes(`${menu.activeName}`)}
                        id={menu.id}
                        key={menu.id}
                      >
                        {menu.name}
                      </MiddleMenu>
                    </Link>
                  )
                )
              } else if (menu.subMenu.length === 0) {
                return (
                  <Link href={`${menu.path}`} key={menu.id}>
                    <MiddleMenu
                      active={path.includes(`${menu.activeName}`)}
                      id={menu.id}
                      key={menu.id}
                    >
                      {menu.name}
                    </MiddleMenu>
                  </Link>
                )
              } else {
                return (
                  <WrapperDropdown
                    active={path.includes(`${menu.activeName}`)}
                    key={menu.id}
                  >
                    {menu.name}
                    <Dropdown type={menu.name}>
                      {menu.subMenu.map((subMenu) => (
                        <Link href={`${subMenu.path}`} key={subMenu.id}>
                          <DropdownList
                            id={subMenu.id}
                            key={subMenu.id}
                            type={menu.name}
                          >
                            {subMenu.name}
                          </DropdownList>
                        </Link>
                      ))}
                    </Dropdown>
                  </WrapperDropdown>
                )
              }
            })}
          </Column>
          <Column style={{ justifyContent: 'flex-end' }}>
            <RightMenu RightMenu={RUN_OUTSCOPE_FUNCTION ? '280px' : '250px'}>
              <ProfileButtonWrapper>
                <UserInfo>
                  {user && user.User && user.User.Email}
                  <UserInfoText>
                    {user && user.UserProfile && user.UserProfile?.Role}
                    {' • '}
                    {user && user.UserProfile && user.UserProfile?.CompanyDesc}
                  </UserInfoText>
                </UserInfo>
              </ProfileButtonWrapper>
              {RUN_OUTSCOPE_FUNCTION && (
                <IconButtonWrapper
                  style={{
                    cursor: 'default'
                  }}
                >
                  <div
                    id='header-notif-btn'
                    onClick={async () => {
                      // await _getNotif()
                      await setNotif(!notif)
                    }}
                    style={{ cursor: 'pointer', display: 'flex' }}
                  >
                    {countNotif > 0 && (
                      <StyledBadge
                        className={classes.count}
                        color='secondary'
                        badgeContent={countNotif}
                      />
                    )}
                    <LightTooltip title='Notification' arrow placement='bottom'>
                      <NotificationsIcon className={classes.notificationIcon} />
                    </LightTooltip>
                    {notif && (
                      <ListNotification
                        dataNotif={dataNotif}
                        user={dataUserProfile}
                        handleClose={_handleCloseModalNotif}
                      />
                    )}
                  </div>
                </IconButtonWrapper>
              )}
              <IconButtonWrapper
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LightTooltip title='Logout' arrow placement='bottom'>
                  <div
                    style={{ display: 'flex' }}
                    onClick={() => {
                      // setLogout(true)
                      setShowLogoutDialog(!showLogoutDialog)
                    }}
                    id='header-logout-btn'
                  >
                    <LogoutIcon width='2vh' height='2vh' />
                  </div>
                </LightTooltip>
              </IconButtonWrapper>
            </RightMenu>
          </Column>
        </Row>
      </HeaderWrapper>
      <Content>{props.children}</Content>
      <ModalConfirmation
        open={showLogoutDialog}
        close={() => {
          // setLogout(false)
          setShowLogoutDialog(!showLogoutDialog)
        }}
        onClickYes={() => {
          _handleLogoutConfirmation()
        }}
        title='Log Out!'
        isConfirm={true}
        message='Are you sure want to logout ?'
        icon={
          <div style={{ marginTop: '15px' }}>
            <LogoutIcon color='blue' width='5vh' height='5vh' />
          </div>
        }
      />
      {/* {_alert()} uncomment this line to enable expired token notification modal */}
    </LayoutBackground>
  )
}

export default Header
