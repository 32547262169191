import { GetDurationMinutes, ConvertLocalTimeGet } from '~/utils'
import { InputSelect, Input } from '~/components'

import SC from './inline-editable-grid.styled'
import { calculateCellWidth } from './inline-editable.functions'
import _ from 'lodash'

// Need to find available checklist field in columns.json or columns.js to set sticky column
// with or without Checkbox
const getAvailableChecklist = (columns) => {
  // Find index field checklist to check checklist field is available in columns
  const foundFieldChecklist = columns.findIndex(
    (col) => col.field === 'checklist'
  )
  const isAvailableChecklist = foundFieldChecklist === -1 ? false : true

  return isAvailableChecklist
}

const SaveButton = ({ onClick, disabled }) => (
  <SC.ButtonIcon>
    <SC.SaveIcon
      disabled={disabled}
      onClick={disabled ? () => {} : onClick}
      id='inline-editable-grid-save-btn'
    />
  </SC.ButtonIcon>
)

const CancelButton = ({ onClick }) => (
  <SC.ButtonIcon>
    <SC.CancelIcon onClick={onClick} id='inline-editable-grid-cancel-btn' />
  </SC.ButtonIcon>
)

const EditButton = ({ onClick, isEdit, disabled }) => (
  <SC.ButtonIcon>
    <SC.EditIcon
      status={disabled ? !disabled : !isEdit}
      id='inline-editable-grid-edit-btn'
      onClick={disabled ? () => {} : isEdit ? () => {} : onClick}
    />
  </SC.ButtonIcon>
)

const RemoveButton = ({ onClick, disabled }) => (
  <SC.ButtonIcon>
    <SC.DeleteIcon
      status={!disabled}
      onClick={disabled ? () => {} : onClick}
      id='inline-editable-grid-remove-btn'
    />
  </SC.ButtonIcon>
)

const ActionWhenInEditCell = ({ handleSave, handleCancel, disabled }) => (
  <SC.ActionWhenInEditCellWrapper>
    <SaveButton onClick={handleSave} disabled={disabled} />
    <CancelButton onClick={handleCancel} />
  </SC.ActionWhenInEditCellWrapper>
)

const ActionWhenNotEditCell = ({
  handleRemove,
  handleEdit,
  isEdit,
  disabled
}) => (
  <SC.ActionWhenInEditCellWrapper>
    <RemoveButton onClick={handleRemove} disabled={disabled} />
    <EditButton onClick={handleEdit} isEdit={isEdit} disabled={disabled} />
  </SC.ActionWhenInEditCellWrapper>
)

const DecideRenderColumn = ({
  form,
  column,
  rowData,
  disabled,
  handleSave,
  handleCancel
}) => {
  const errors = form?.errors
  if (column.field.toLocaleLowerCase() === 'action') {
    return (
      <div>
        <ActionWhenInEditCell
          handleSave={handleSave}
          handleCancel={handleCancel}
          disabled={disabled}
        />
      </div>
    )
  }

  if (column.render.toLocaleLowerCase() === 'dropdown') {
    let idName =
      column.title.toLowerCase() == 'unit/code'
        ? 'unit-code'
        : column.title.toLowerCase() == 'unit/name'
        ? 'unit-name'
        : column.field

    return (
      <>
        <InputSelect
          form={form}
          isTitle={false}
          name={column.field}
          optionText={column.text}
          placeholder={column.title}
          optionValueKey={column.value}
          style={{ fontSize: '0.75rem' }}
          id={`inline-editable-grid-select-${idName}`}
          options={
            rowData[column.options] && rowData[column.options].length > 0
              ? // Filter some old CCU Unit Description data that's empty
                rowData[column.options].filter(
                  (item) => item !== undefined && item.description !== ''
                )
              : []
          }
        />
        {column.field === 'ccu_unit_code' && form.values.status ? (
          <SC.TextInfoEdit>{form.values.remarks}</SC.TextInfoEdit>
        ) : null}
      </>
    )
  }

  if (column.render.toLocaleLowerCase() === 'calendar') {
    if (!column.editable) {
      return <SC.ColumnText>{form.values[column.field]}</SC.ColumnText>
    }

    return (
      <div>
        <SC.InputDateTime
          form={form}
          name={column.field}
          id={`inline-editable-grid-calendar-${column.field}`}
        />
        <SC.Error>{errors?.[column.field]}</SC.Error>
      </div>
    )
  }

  if (column.render.toLocaleLowerCase() === 'number') {
    if (!column.editable) {
      return <SC.ColumnText>{form.values[column.field]}</SC.ColumnText>
    }

    return (
      <Input
        form={form}
        type='number'
        isTitle={false}
        name={column.field}
        placeholder={column.title}
        InputProps={{ inputProps: { min: 0 } }}
        id={`inline-editable-grid-number-${column.field}`}
      />
    )
  }

  if (column.render.toLocaleLowerCase() === 'float') {
    return (
      <Input
        form={form}
        type='float'
        isTitle={false}
        name={column.field}
        placeholder={column.title}
        InputProps={{ inputProps: { min: 0 } }}
        id={`inline-editable-grid-number-${column.field}`}
      />
    )
  }

  if (column.field.toLocaleLowerCase() == 'billing_dur_time') {
    return (
      <SC.ColumnText>
        {GetDurationMinutes(
          form.values.billing_start_time,
          form.values.billing_finish_time,
          form.values.break_time
        )}
      </SC.ColumnText>
    )
  }

  if (column.field.toLocaleLowerCase() == 'duration_time') {
    return (
      <SC.ColumnText>
        {GetDurationMinutes(
          form.values.start_time,
          form.values.finish_time,
          form.values.break_time
        )}
      </SC.ColumnText>
    )
  }

  return (
    <Input
      isTitle={false}
      form={form}
      name={column.field}
      placeholder={column.title}
      id={`inline-editable-grid-input-${column.field}`}
      InputProps={{
        readOnly: !column.editable
      }}
    />
  )
}

const InEditRow = ({
  id,
  sticky,
  columns,
  rowData,
  disabled,
  formSubmit,
  handleSave,
  isFixedCell,
  handleCancel
}) => {
  const isAvailableChecklist = getAvailableChecklist(columns)

  return (
    <tr id={id}>
      {columns.map((column, index) => {
        const cellWidth = calculateCellWidth(isFixedCell, column.width)
        if (
          column.customRender &&
          column.field.toLocaleLowerCase() === 'checklist' &&
          sticky
        ) {
          return (
            <td
              style={{
                position: 'sticky',
                left: '0px',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              {column.customRender(rowData, index)}
            </td>
          )
        } else if (column.field.toLocaleLowerCase() === 'action' && sticky) {
          return (
            <td
              style={{
                zIndex: 1,
                left: isAvailableChecklist ? '50px' : '0px',
                position: 'sticky',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              className='sticky-col'
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideRenderColumn
                column={column}
                form={formSubmit}
                rowData={rowData}
                disabled={disabled}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </td>
          )
        } else if (
          column.field.toLocaleLowerCase() === 'product_name' &&
          sticky
        ) {
          return (
            <td
              style={{
                zIndex: 1,
                left: isAvailableChecklist ? '150px' : '0px',
                position: 'sticky',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              className='sticky-col'
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideRenderColumn
                column={column}
                form={formSubmit}
                rowData={rowData}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </td>
          )
        } else if (column.field.toLocaleLowerCase() === 'action' && !sticky) {
          return (
            <td
              className='sticky-col'
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideRenderColumn
                column={column}
                form={formSubmit}
                rowData={rowData}
                disabled={disabled}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </td>
          )
        }

        let idName =
          column.title.toLowerCase() == 'unit/code'
            ? 'unit-code'
            : column.title.toLowerCase() == 'unit/name'
            ? 'unit-name'
            : column.field
        return (
          <td key={index} width={cellWidth} id={`grid-${idName}-${id}`}>
            <DecideRenderColumn
              column={column}
              form={formSubmit}
              rowData={rowData}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </td>
        )
      })}
    </tr>
  )
}

const DecideNotInEditCell = ({
  column,
  handleEdit,
  handleRemove,
  dataItem,
  isEdit,
  disabled,
  location
}) => {
  if (column.field.toLocaleLowerCase() == 'action') {
    return (
      <div>
        <ActionWhenNotEditCell
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          isEdit={isEdit}
          disabled={disabled}
        />
      </div>
    )
  }

  if (column.render.toLocaleLowerCase() == 'calendar') {
    return (
      <SC.ColumnText>
        {location
          ? ConvertLocalTimeGet(
              location,
              dataItem[column.field],
              'DD MMM YYYY HH:mm'
            )
          : dataItem[column.field]}
      </SC.ColumnText>
    )
  }

  if (column.field.toLocaleLowerCase() == 'duration_time') {
    return (
      <SC.ColumnText>
        {GetDurationMinutes(
          dataItem.start_time,
          dataItem.finish_time,
          dataItem.break_time
        )}
      </SC.ColumnText>
    )
  }

  if (column.field.toLocaleLowerCase() == 'billing_dur_time') {
    return (
      <SC.ColumnText>
        {GetDurationMinutes(
          dataItem.billing_start_time,
          dataItem.billing_finish_time,
          dataItem.break_time
        )}
      </SC.ColumnText>
    )
  }

  if (column.field.toLocaleLowerCase() == 'ccu_unit_code') {
    return (
      <>
        <SC.ColumnText>{dataItem[column.notInEditValue]}</SC.ColumnText>
        <SC.ColumnText>
          {dataItem.status ? (
            <SC.TextInfo>{dataItem.remarks}</SC.TextInfo>
          ) : null}
        </SC.ColumnText>
      </>
    )
  }

  if (column.editor && column.editor.toLocaleLowerCase() == 'numeric') {
    return (
      <SC.ColumnText>
        {parseFloat(dataItem[column.field]).toFixed(3)}
      </SC.ColumnText>
    )
  }

  if (column.notInEditValue) {
    return <SC.ColumnText>{dataItem[column.notInEditValue]}</SC.ColumnText>
  }

  return <SC.ColumnText>{dataItem[column.field]}</SC.ColumnText>
}

const InNotEditRow = ({
  forceEdit,
  noAction,
  isFixedCell,
  dataItem,
  columns,
  id,
  handleRemove,
  handleEdit,
  isEdit,
  sticky,
  disabled,
  location
}) => {
  const isAvailableChecklist = getAvailableChecklist(columns)

  return (
    <tr id={id}>
      {columns.map((column, index) => {
        const cellWidth = calculateCellWidth(isFixedCell, column.width)
        if (
          column.customRender &&
          column.field.toLocaleLowerCase() === 'checklist' &&
          sticky
        ) {
          // Not render anything when is not edited and not available checklist in
          if (isAvailableChecklist && !forceEdit) {
            return null
          }
          return (
            <td
              style={{
                position: 'sticky',
                left: '0px',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              {column.customRender(dataItem)}
            </td>
          )
        } else if (column.field.toLocaleLowerCase() === 'action' && sticky) {
          return (
            <td
              style={{
                position: 'sticky',
                left: isAvailableChecklist ? '50px' : '0px',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideNotInEditCell
                isEdit={isEdit}
                column={column}
                location={location}
                dataItem={dataItem}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
              />
            </td>
          )
        } else if (
          column.field.toLocaleLowerCase() === 'product_name' &&
          sticky &&
          !noAction
        ) {
          return (
            <td
              style={{
                position: 'sticky',
                left: isAvailableChecklist ? '150px' : '100px',
                backgroundColor: 'rgb(244, 246, 249)'
              }}
              className='sticky-col'
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideNotInEditCell
                isEdit={isEdit}
                column={column}
                location={location}
                dataItem={dataItem}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
              />
            </td>
          )
        } else if (column.field.toLocaleLowerCase() === 'action' && !sticky) {
          return (
            <td
              className='sticky-col'
              key={index}
              width={cellWidth}
              id={`grid-${column.field}-${id}`}
            >
              <DecideNotInEditCell
                isEdit={isEdit}
                column={column}
                disabled={disabled}
                dataItem={dataItem}
                location={location}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
              />
            </td>
          )
        }

        let idName =
          column.title.toLowerCase() == 'unit/code'
            ? 'unit-code'
            : column.title.toLowerCase() == 'unit/name'
            ? 'unit-name'
            : column.field
        return (
          <td key={index} width={cellWidth} id={`grid-${idName}-${id}`}>
            <DecideNotInEditCell
              isEdit={isEdit}
              column={column}
              dataItem={dataItem}
              location={location}
              handleEdit={handleEdit}
              handleRemove={handleRemove}
            />
          </td>
        )
      })}
    </tr>
  )
}

const TableHeader = ({
  noAction,
  isFixedCell,
  tableWidth,
  columns,
  sticky
}) => {
  const isAvailableChecklist = getAvailableChecklist(columns) && !noAction

  return (
    <table
      id='custom-table'
      width={tableWidth}
      style={{ position: 'sticky', top: 0, zIndex: 99 }}
    >
      <thead
        style={{ background: 'rgb(221, 234, 255)', color: 'rgb(51, 51, 51)' }}
      >
        <SC.TableHeaderRow>
          {columns.map((column, index) => {
            const cellWidth = calculateCellWidth(isFixedCell, column.width)

            return column.customRenderHeader &&
              column.field.toLocaleLowerCase() === 'checklist' &&
              sticky ? (
              <>
                {!isAvailableChecklist ? null : (
                  <th
                    style={{
                      position: 'sticky',
                      left: '0px',
                      background: 'rgb(221, 234, 255)',
                      color: 'rgb(51, 51, 51)',
                      fontWeight: '500'
                    }}
                    key={index}
                    width={cellWidth}
                  >
                    {column.customRenderHeader}
                  </th>
                )}
              </>
            ) : column.title.toLocaleLowerCase() === 'action' && sticky ? (
              <th
                style={{
                  position: 'sticky',
                  left: isAvailableChecklist ? '50px' : '0px',
                  background: 'rgb(221, 234, 255)',
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '500'
                }}
                key={index}
                width={cellWidth}
              >
                {column.title}
              </th>
            ) : column.field.toLocaleLowerCase() === 'product_name' &&
              sticky &&
              !noAction ? (
              <th
                style={{
                  position: 'sticky',
                  left: isAvailableChecklist ? '150px' : '0px',
                  background: 'rgb(221, 234, 255)',
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '500'
                }}
                key={index}
                width={cellWidth}
              >
                {column.title}
              </th>
            ) : column.title.toLocaleLowerCase() === 'action' && !sticky ? (
              <th
                className='sticky-col'
                key={index}
                width={cellWidth}
                style={{
                  background: 'rgb(221, 234, 255)',
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '500'
                }}
              >
                {column.title}
              </th>
            ) : (
              <th
                key={index}
                width={cellWidth}
                style={{
                  background: 'rgb(221, 234, 255)',
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '500'
                }}
              >
                {column.title}
              </th>
            )
          })}
        </SC.TableHeaderRow>
      </thead>
    </table>
  )
}

const TableBody = ({
  forceEdit,
  data,
  sticky,
  columns,
  rowData,
  disabled,
  noAction,
  location,
  subModule,
  tableWidth,
  formSubmit,
  handleSave,
  handleEdit,
  tableheight,
  isFixedCell,
  handleCancel,
  handleRemove
}) => {
  let isEdit =
    data &&
    data.length > 0 &&
    data.findIndex((record) => record.edit === true) < 0
      ? false
      : true

  return (
    <div style={{ width: tableWidth, height: tableheight }}>
      <table id={'custom-table'} width={tableWidth}>
        <tbody>
          {data &&
            data.length > 0 &&
            data
              .filter((item) => !item.delete)
              .map((item, index) => {
                const disableAction =
                  !_.isEmpty(subModule) && subModule !== item.source
                return item.edit ? (
                  <InEditRow
                    id={index}
                    sticky={sticky}
                    columns={columns}
                    rowData={rowData}
                    disabled={disabled}
                    formSubmit={formSubmit}
                    isFixedCell={isFixedCell}
                    handleSave={() => handleSave(item)}
                    handleCancel={() => handleCancel(item)}
                  />
                ) : (
                  <InNotEditRow
                    id={index}
                    sticky={sticky}
                    forceEdit={forceEdit}
                    isEdit={isEdit}
                    dataItem={item}
                    editIndex={index}
                    columns={columns}
                    location={location}
                    noAction={noAction}
                    disabled={disableAction}
                    isFixedCell={isFixedCell}
                    handleEdit={() => handleEdit(item)}
                    handleRemove={() => handleRemove(item)}
                  />
                )
              })}
        </tbody>
      </table>
    </div>
  )
}

export default {
  TableBody,
  TableHeader
}
